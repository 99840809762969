<template>
  <div>
    <search @submit="submitSearch" />

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="target_name" slot-scope="text, record">
        <a @click="handleShow(record)">查看详情</a>
      </span>
  
      <span slot="actions" slot-scope="text, record">
        <a @click="handleApproval(record.id)" v-if="record.status_slug === 'checking'">审核</a>
        <a @click="handleShowDetail(record.id)" v-else>查看详情</a>
      </span>
    </a-table>

    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />

    <approval
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <show
      v-if="isShowModal"
      :visible.sync="isShowModal"
      :id="showing_id"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Search from '@/pages/task/reports/Search.vue'
import Pagination from "@/components/Pagination"
import formatTime from '@/utils/formatTime'
import { ReportRecordModel } from '@/models/report_record.js'
const reportRecordModel = new ReportRecordModel()

export default {
  name: 'ReportRecordList',
  components: {
    Search,
    Pagination,
    approval: () => import('@/pages/task/reports/Approval.vue'),
    show: () => import('@/pages/task/reports/Show.vue')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      editingId: 0,
      showing_id: 0,
      total: 0,
      current: 1,
      isShowModal: false,
      isShowEditModal: false
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '用户账号',
          dataIndex: 'user_name'
        },
        {
          title: '昵称',
          dataIndex: 'nick_name'
        },
        {
          title: '举报内容',
          dataIndex: 'content'
        },
        {
          title: '举报对象',
          scopedSlots: { customRender: 'target_name' },
          dataIndex: 'target_name'
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '平台反馈',
          dataIndex: 'reason',
          width: 200,
          ellipsis: true
        },
        {
          title: '举报时间',
          dataIndex: 'created_at',
          customRender: formatTime
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        },
      ]
    }
  },
  methods: {
    submitSearch(search) {
      this.current = 1
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    handleChange(page) {
      this.current = page
      this.fetchData()
    },

    handleShow(record) {
      let url = "";
      if (record.record_type == "App") {
        url =
          process.env.VUE_APP_PORT +
          "/apps/" +
          record.record_id +
          "?type=all";
      } else if (record.record_type == "Document") {
        url =
          process.env.VUE_APP_PORT +
          "/doc/documents/" +
          record.hash_id
      }
      window.open(url)
    },

    handleApproval(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    handleShowDetail(id) {
      this.showing_id = id
      this.isShowModal = true
    },
    
    fetchData() {
      this.loading = true
      const data = {
        ...this.query,
        page: this.current
      }
      reportRecordModel.index(data).then(res => {
        if (res.code === 0) {
          this.data = res.result
          this.total = res.total
        }
        this.loading = false
      })
    }
  }
}
</script>
