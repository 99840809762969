import {HTTP} from '../utils/axios'

class ReportRecordModel extends HTTP {
  // 列表
  index(data) {
      return this.request({
          url: 'board/report_records',
          method: "GET",
          data: data
      })
  }

  /*修改*/
  update(data, id) {
    return this.request({
        url: "board/report_records/" + id,
        method: "PATCH",
        data: data,
    })
  }

  // 表单
  show(id) {
    return this.request({
        url: 'board/report_records/' + id,
        method: "GET"
    })
  }
}

export { ReportRecordModel }